.block.remote-counter {
  .block-content {
    position: relative;
    height: auto;
    padding: 2.5rem 0;
    text-align: center;
    background-color: $secondary;
    color: $secondary-text;
    z-index: 0;

    &.with-bg {
      color: $white;

      h2,
      h3,
      h4,
      h5,
      h6,
      a {
        color: $white;
      }
    }

    @media (max-width: #{map-get($grid-breakpoints, lg) - 1px}) {
      padding: 2rem 1rem;
    }
  }

  .background-image {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.65);
      content: '';
      left: 0;
      top: 0;
    }
  }

  .title,
  .text {
    p:last-of-type {
      margin-bottom: 0;
    }

    h1:last-child,
    h2:last-child,
    h3:last-child,
    h4:last-child,
    h5:last-child {
      margin-bottom: 0;
    }

    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child {
      margin-top: 0;
    }
  }

  .slate-editor {
    [data-slate-placeholder='true'] {
      opacity: 0.7 !important;
    }
  }

  .help {
    font-size: 0.8rem;
    opacity: 0.7;
    font-style: italic;
  }

  .counter {
    margin: 40px 0;
    font-size: 2rem;
    line-height: 1em;
  }
}
