.block.listing {
  .card-with-image-template,
  .in-evidence,
  .contentInEvidenceTemplate,
  .list-template {
    .img-responsive-wrapper {
      .enhance-link {
        display: none;
      }
    }
  }
}
