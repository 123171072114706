.cms-ui {
  .blocks-widget {
    .inline.field {
      .ui.grid {
        .four.wide.column,
        .eight.wide.column {
          width: 100% !important; //allarghiamo i campi coi blocchi perchè hanno monitor piccoli
        }
      }
    }
  }

  /* accessibilità dei placeholder nei campi di input del blocco Icone */
  .block.iconBlocks {
    .single-block {
      .card-body {
        .iconblock-title,
        .iconblock-text {
          .public-DraftEditorPlaceholder-inner {
            color: $input-placeholder-color;
          }
        }
      }
    }
  }

  /* accessibilità dei placeholder nei campi di input del blocco Contatti */
  .block.contacts {
    .single-block {
      .card-body {
        .contact-title,
        .contact-text,
        .contact-info {
          .public-DraftEditorPlaceholder-inner {
            color: $input-placeholder-color;
          }
        }
      }
    }
  }
}

#toolbar .menu-more li.display-select {
  display: none;
}

.cms-ui .controlpanel {
  .ui.segment {
    h3.ui.header {
      font-size: 1rem;
    }
  }
}

.cms-ui hr {
  margin: 16px 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

//fix drag block button and delete block button colors
body.cms-ui.has-toolbar.has-sidebar .drag.handle.wrapper,
.drag.handle.wrapper,
.block .ui.basic.button.delete-button,
.block.cta_block .ui.basic.button.delete-button,
.block.numbersBlock > .ui.basic.button.delete-button,
.block.iconBlocks .ui.basic.button.delete-button,
.block.contacts .ui.basic.button.delete-button {
  background-color: #fff !important;
  color: #575757 !important;
  border: 1px solid #fff !important;
  border-radius: 100% !important;
  padding: 5px !important;
  margin-right: -40px !important;
  line-height: 0;
  margin-top: -5px;
  min-height: unset;
  svg,
  svg:not(:hover) {
    line-height: 0;
    fill: currentColor !important;
  }
  .icon {
    background: none;
  }
  &:hover {
    background-color: black !important;
    color: #fff !important;
    svg {
      fill: #fff !important;
    }
  }
}

//riduco la distanza tra un input checkbox e il suo help
//riduco la dimensione del font di help
body.cms-ui {
  .inline.field.help:has(> .ui.grid .ui.checkbox) {
    .help {
      padding-top: 0;
    }
    .wrapper {
      min-height: 40px;
    }
  }
}

//metto lo sfondo al campo di testo a blocchi
body.cms-ui {
  .blocks-widget-container {
    padding: 0 2.5em;
    background-color: #fafafa;
    margin-bottom: 1rem;
  }
}

body.cms-ui.has-toolbar.has-sidebar .drag.handle.wrapper,
.drag.handle.wrapper {
  margin-left: -40px !important;
  &:hover {
    background-color: #4e8aa5 !important;
  }
}

//fix delete block button colors
body.cms-ui {
  .block .ui.basic.button.delete-button,
  .block.cta_block .ui.basic.button.delete-button,
  .block.numbersBlock > .ui.basic.button.delete-button,
  .block.iconBlocks .ui.basic.button.delete-button,
  .block.contacts .ui.basic.button.delete-button {
    margin-right: -39px !important;
    &:hover {
      background-color: #d90062 !important;
    }
  }
}

.block .subblock-edit .ui.basic.button.delete-button {
  border-radius: 100% !important;
  margin-left: 0.5rem;
  color: #575757 !important;
}

//nascondo i campi id_lighthouse
body.cms-ui {
  .field {
    &[class*='id_lighthouse'] {
      display: none;
    }
  }
}
//pannello di controllo footer settings
.cms-ui.section-editable-footer-settings {
  .inline.field[class$='-newsletterSubscribe'] {
    display: none;
  }

  .field-wrapper-footerTop {
    .blocks-widget-container {
      background-color: $footer-top-background;
      color: $white;
      margin: 0 -1rem;
      .blocks-form {
        padding: 0 1rem;
      }
      a {
        color: $white;
        text-decoration: underline;
      }
      h4 a {
        color: $white;
      }

      .link-list-wrapper ul li {
        a {
          color: $white;
        }
      }

      .gridBlock-empty-placeholder {
        color: #000;
      }
    }
  }
}
